<template>
  <div class="ticket">
    <base-loader v-if="isLoading" class="arhiv-ticket__loader" />
    <div v-if="isReady" class="ticket-info">
      <plain-button :to="{ name: 'ArchiveRequest' }" icon="arrow-left" color="dim">
        {{ $t('back') }}
      </plain-button>
      <div class="ticket-info__title">
        <page-title class="ticket-info__title-name">
          {{ ticketName }}
        </page-title>
        <main-label :color="'success'" class="ticket-info__title-status">{{
          $t('arhiv')
        }}</main-label>
      </div>
      <transition-group mode="slide-fade">
        <base-dialog
          v-for="msg in ticketList"
          :key="msg.id"
          :name="msg.user.realname"
          :position="msg.user.position"
          :time="msg.date_post_locale"
          :text="msg.body"
          :image="srcAva(msg.type, msg.avatar)"
          :type="msg.type"
          :files="msg.file"
          class="ticket-info__dialogs"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
/**
 * @TODO:
 * - сравнить с CurrentTicket.vue, если отличаются только возможностью ответа,
 * использовать один компонент с соотвестствующими настройками
 */
import BaseDialog from '@/components/BaseChat/BaseDialog';
import MainLabel from '@/components/Labels/MainLabel';
export default {
  name: 'Ticket',
  components: {
    BaseDialog,
    MainLabel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    mainMessage() {
      return this.$store.state.moduleSupport.mainMessage;
    },
    ticketName() {
      return this.$store.state.moduleSupport.currentTicketTheme;
    },
    ticketList() {
      return this.$store.state.moduleSupport.currentTicket;
    },
    isReady() {
      return this.ticketList && this.ticketList.length;
    },
  },
  mounted() {
    this.$store.dispatch('moduleSupport/fetchCurrentTicket', this.id);
    this.isLoading = false;
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleSupport/reset');
    next();
  },
  methods: {
    srcAva: function (type, image) {
      if (type === 'outcoming') {
        return this.$store.getters['moduleProfile/avatar'];
      } else if (type === 'incoming') {
        // return `https://my.rusonyx.ru${image}`;
        return `${this.$store.state.moduleApp.host}${image}`;
      }
      return '';
    },
    routeTo() {
      this.$router.push({ name: 'ArchiveRequest' });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "arhiv": "Вопрос решен",
    "back": "Назад"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.ticket {
  display: flex;
  flex-direction: column;
  width: 100%
  max-width: 925px;

  &-info {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between
      margin-top: 1rem;
      margin-bottom: 2.5rem;

      &-status {
        flex: 0 0 auto;
        order: 3;
        margin: 0 0 0 auto;

        +breakpoint(sm-and-up) {
          order: 2;
          margin: 0 1.25rem 0.25rem;
        }
      }
    }
    &__firstMessage {
      margin-bottom: 2.2rem;
      overflow: auto;
    }

    &__dialogs {
      margin-bottom: 4rem;
    }
  }
}
</style>
